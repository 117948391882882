import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import ProductDRight from "../../components/templates/product/productDRight";
import ProductSwiper from "../../components/templates/product/productSwiper";
import ProductBottomSwiper from "../../components/templates/product/productBottomSwiper";
import RelatedProductSwiper from "../../components/templates/product/relatedProductSwiper";
import Description from "../../components/organisms/product/description";
import ReviewList from "../../components/organisms/product/reviewList";
import Guide from "../../components/organisms/product/guide";
import QAList from "../../components/organisms/product/qaList";

import useDisplay from "../../hooks/useDisplay";
import MerchApi from "../../api/merchApi";

import { errorHandler } from "../../util/errorHandler";
import WishApi from "../../api/wishApi";

const ProductDetail = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { id } = useParams();

  const [productImgList, setProductImgList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [clickedIndex, setClickedIndex] = useState(1);
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [isWish, setIsWish] = useState(false);

  const [tabCategoryList, setTabCategoryList] = useState([
    {
      title: "Thông tin sản phẩm",
      isActive: true,
    },
    { title: "REVIEW", isActive: false },
    {
      title: isSmall
        ? "Vận chuyển /\nTrả hàng / Hoàn tiền"
        : "Vận chuyển / Trả hàng / Hoàn tiền",
      isActive: false,
    },
    { title: "Q&A", isActive: false },
  ]);
  const [merchInfo, setMerchInfo] = useState(null);

  const tabCategoryClickFunc = (title) => {
    let temp = tabCategoryList.map((item) => {
      return item.title === title
        ? {
            ...item,
            isActive: true,
          }
        : {
            ...item,
            isActive: false,
          };
    });

    setTabCategoryList(temp);
  };

  useEffect(() => {
    merchDetailFunc();
  }, [id]);

  const merchDetailFunc = async () => {
    try {
      const response = (await MerchApi.detail(id)).data.data;

      setMerchInfo(response.content);
      setIsWish(response.content.isWish);

      if (response.content.video.id) {
        setProductImgList([response.content.video, ...response.content.images]);
      } else {
        setProductImgList(response.content.images);
      }

      await relatedProductFunc(
        response.content.category.id,
        response.content.id
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const wishTriggerFunc = async () => {
    try {
      await WishApi.wishTrigger(merchInfo.id);
      setIsWish(!isWish);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const relatedProductFunc = async (categoryId, merchId) => {
    try {
      const params = {
        page: 1,
        size: 4,
        categoryId: categoryId,
        orderType: 2,
      };

      const response = (await MerchApi.getList(params)).data.data;

      let temp = response.content.filter((item) => item.id !== merchId);
      setRelatedProductList(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ProductDetailSection>
        {merchInfo ? (
          <div
            style={{
              width: isMobile ? "100%" : "auto",
              padding: isMobile
                ? "40px 20px 80px 20px"
                : isTablet
                ? "40px 20px 120px 20px"
                : "55px 0px 80px 0px",
            }}
          >
            <PCContainer
              style={{
                flexDirection: isSmall ? "column" : "row",
              }}
            >
              <div
                style={{
                  width: isMobile ? "100%" : 640,
                  marginRight: isSmall ? 0 : 20,
                  position: "relative",
                }}
              >
                {isWish ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      wishTriggerFunc();
                    }}
                    style={{
                      position: "absolute",
                      right: 14,
                      top: 14,
                      width: isMobile ? 30 : 48,
                      height: isMobile ? 30 : 48,
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    src="/assets/icons/heart_a.png"
                    alt="headrt"
                  />
                ) : (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      wishTriggerFunc();
                    }}
                    style={{
                      position: "absolute",
                      right: 14,
                      top: 14,
                      width: isMobile ? 30 : 48,
                      height: isMobile ? 30 : 48,
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    src="/assets/icons/heart_d.png"
                    alt="headrt"
                  />
                )}

                {productImgList?.length > 0 ? (
                  <ProductSwiper
                    dataList={productImgList}
                    setActiveIndex={setActiveIndex}
                    clickedIndex={clickedIndex}
                  />
                ) : null}
                {productImgList?.length > 0 ? (
                  <ProductBottomSwiper
                    dataList={productImgList}
                    activeIndex={activeIndex}
                    setClickedIndex={setClickedIndex}
                  />
                ) : null}

                {isSmall ? <ProductDRight data={merchInfo} /> : null}
              </div>

              {isSmall ? null : <ProductDRight data={merchInfo} />}
            </PCContainer>

            <PCContainer
              style={{
                flexDirection: "column",
              }}
            >
              <OverflowXHidden $isMobile={isMobile}>
                {tabCategoryList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        width: isMobile ? "auto" : isTablet ? 160 : 295,
                        height: isMobile ? "auto" : 72,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: item.isActive
                          ? "2px solid #FF649C"
                          : "none",
                        padding: isMobile ? "12px 16px" : "0px",
                      }}
                      onClick={() => {
                        tabCategoryClickFunc(item.title);
                      }}
                    >
                      <PretendardText
                        style={{
                          textAlign: "center",
                          fontSize: isMobile ? 14 : 16,
                          lineHeight: isMobile ? "19.6px" : "23.2px",
                          fontWeight: 600,
                          color: item.isActive ? "#313131" : "#939393",
                          width: "max-content",
                        }}
                      >
                        {index == 2
                          ? isMobile
                            ? "Vận chuyển / Trao đổi / Trả lại"
                            : item.title
                          : item.title}
                      </PretendardText>
                    </div>
                  );
                })}
              </OverflowXHidden>

              {tabCategoryList[0].isActive ? (
                <Description content={merchInfo.content} />
              ) : null}
              {tabCategoryList[1].isActive ? (
                <ReviewList merchId={merchInfo.id} />
              ) : null}
              {tabCategoryList[2].isActive ? <Guide /> : null}
              {tabCategoryList[3].isActive ? (
                <QAList merchId={merchInfo.id} />
              ) : null}

              {relatedProductList.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: isMobile ? 40 : 60,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      lineHeight: "26.1px",
                      fontWeight: 600,
                      color: "#313131",
                      marginBottom: 20,
                    }}
                  >
                    Sản phẩm liên quan
                  </PretendardText>
                  <RelatedProductSwiper dataList={relatedProductList} />
                </div>
              ) : null}
            </PCContainer>
          </div>
        ) : null}

        <FooterBtnGroup isBottomBtn={true} />
      </ProductDetailSection>
    </>
  );
};

export default ProductDetail;

const ProductDetailSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(220, 220, 220);

  height: ${(props) => (props.$isMobile ? "auto" : "72px")};
  margin: 40px 0px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
