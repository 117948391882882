import React, { useEffect, useRef, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import Pagination from "../../components/organisms/pagination";
import ProductItem from "../../components/templates/product/productItem";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import SelectM from "../../components/atoms/select/selectM";
import Empty from "../../components/organisms/empty";

import { route } from "../../router/route";
import { errorHandler } from "../../util/errorHandler";
import CategoryApi from "../../api/categoryApi";
import MerchApi from "../../api/merchApi";

const CategoryProduct = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [clickedCategoryValue, setClickedCategoryValue] = useState(0);
  const [categoryList, setCategoryList] = useState([]);

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [clickedSubCategoryValue, setClickedSubCategoryValue] = useState(0);

  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([
    {
      value: "Gần nhất",
      label: "Gần nhất",
    },
    {
      value: "Bán chạy",
      label: "Bán chạy",
    },
    {
      value: "Yêu thích",
      label: "Yêu thích",
    },
  ]);

  const [categoryProductList, setCategoryProductList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 20, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const containerRef = useRef(null);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      setTimeout(() => {
        const container = containerRef.current;
        // console.log(container);
        if (container) {
          setHasHorizontalScroll(container.scrollWidth > container.clientWidth);
        }
      }, 100);
    };

    // 초기 확인
    checkScroll();

    // 윈도우 리사이즈 시 스크롤 여부 재확인
    window.addEventListener("resize", checkScroll);
    return () => {
      window.removeEventListener("resize", checkScroll);
    };
  }, [clickedCategoryValue]);

  useEffect(() => {
    setClickedCategoryValue(
      searchParams.get("categoryValue")
        ? Number(searchParams.get("categoryValue"))
        : 0
    );
    setClickedSubCategoryValue(
      searchParams.get("subCategoryValue")
        ? Number(searchParams.get("subCategoryValue"))
        : 0
    );
    setFilterValue(
      searchParams.get("filterValue") ? searchParams.get("filterValue") : ""
    );

    categoryListFunc();
  }, [location]);

  useEffect(() => {
    if (clickedCategoryValue !== 0 && categoryList.length > 0) {
      subCategoryListFunc();
    }
  }, [clickedCategoryValue, categoryList]);

  useEffect(() => {
    if (clickedSubCategoryValue !== 0) {
      merchListFunc(false);
    }
  }, [clickedSubCategoryValue]);

  const categoryListFunc = async () => {
    try {
      const response = await CategoryApi.getList();

      if (
        response.data?.data?.content &&
        response.data.data.content.length > 0
      ) {
        setCategoryList(response.data.data.content);

        if (
          searchParams.get("categoryValue") == null ||
          searchParams.get("categoryValue") == 0
        ) {
          setClickedCategoryValue(response.data.data.content[0].id);
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const subCategoryListFunc = () => {
    if (clickedCategoryValue && categoryList.length > 0) {
      const subTemp = categoryList.find(
        (item) => item.id === clickedCategoryValue
      )?.childCategories;

      if (subTemp && subTemp.length > 0) {
        setSubCategoryList(subTemp);

        if (
          searchParams.get("subCategoryValue") == null ||
          searchParams.get("subCategoryValue") == 0
        ) {
          setClickedSubCategoryValue(subTemp[0].id);
        }
      } else {
        setSubCategoryList([]);
        merchListFunc(true);
      }
    }
  };

  const merchListFunc = async (firstCategory) => {
    try {
      setCategoryProductList([]);

      const params = {
        page: page,
        size: pagination.size,
        categoryId: firstCategory
          ? clickedCategoryValue
          : clickedSubCategoryValue,
        orderType:
          searchParams.get("filterValue") === "Yêu thích"
            ? 3
            : searchParams.get("filterValue") === "Bán chạy"
            ? 2
            : 1,
      };

      const response = (await MerchApi.getList(params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setCategoryProductList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <CategoryProductSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: isSmall ? "80px 20px 80px 20px" : "80px 0px 80px 0px",
          }}
        >
          <MenuOverflowXHidden $isMobile={isMobile}>
            {categoryList.map((item, index) => {
              return (
                <HomeIconMenuContain
                  style={{
                    marginRight:
                      categoryList.length === index + 1
                        ? 0
                        : isMobile
                        ? 16
                        : 40,
                  }}
                  key={index}
                  onClick={() => {
                    navigate(
                      `${route.category_product}/1?categoryValue=${item.id}&subCategoryValue=0&filterValue=${filterValue}`
                    );
                  }}
                  $isMobile={isMobile}
                >
                  <img
                    style={{
                      width: isMobile ? 54 : 72,
                      height: isMobile ? 54 : 72,
                    }}
                    src={
                      item.id === clickedCategoryValue
                        ? `${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileNameActive}`
                        : `${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileNameInActive}`
                    }
                    alt="category"
                  />
                  <HomeIconMenuText
                    $isMobile={isMobile}
                    style={{
                      color:
                        item.id === clickedCategoryValue
                          ? "#FF649C"
                          : "rgb(49, 49, 49)",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                </HomeIconMenuContain>
              );
            })}
          </MenuOverflowXHidden>
          {isSmall ? (
            <OverflowXHidden $isSmall={isSmall}>
              {subCategoryList && subCategoryList.length > 0
                ? subCategoryList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          cursor: "pointer",
                          padding: isMobile ? "12px 16px" : "13px 35px",
                          borderBottom:
                            item.id === clickedSubCategoryValue
                              ? "2px solid #FF649C"
                              : "none",
                        }}
                        onClick={() => {
                          navigate(
                            `${route.category_product}/1?categoryValue=${clickedCategoryValue}&subCategoryValue=${item.id}&filterValue=${filterValue}`
                          );
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: isMobile ? 14 : 16,
                            lineHeight: isMobile ? "19.6px" : "23.2px",
                            fontWeight: isMobile
                              ? item.id === clickedSubCategoryValue
                                ? 600
                                : 500
                              : 600,
                            color:
                              item.id === clickedSubCategoryValue
                                ? "#313131"
                                : "#939393",
                            width: "max-content",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      </div>
                    );
                  })
                : null}
            </OverflowXHidden>
          ) : (
            <ScrollBarOverflowXHidden
              ref={containerRef}
              style={{
                paddingBottom: hasHorizontalScroll ? 8 : 0,
              }}
            >
              {subCategoryList && subCategoryList.length > 0
                ? subCategoryList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          cursor: "pointer",
                          padding: isMobile ? "12px 16px" : "13px 35px",
                          borderBottom:
                            item.id === clickedSubCategoryValue
                              ? "2px solid #FF649C"
                              : "none",
                        }}
                        onClick={() => {
                          navigate(
                            `${route.category_product}/1?categoryValue=${clickedCategoryValue}&subCategoryValue=${item.id}&filterValue=${filterValue}`
                          );
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: isMobile ? 14 : 16,
                            lineHeight: isMobile ? "19.6px" : "23.2px",
                            fontWeight: isMobile
                              ? item.id === clickedSubCategoryValue
                                ? 600
                                : 500
                              : 600,
                            color:
                              item.id === clickedSubCategoryValue
                                ? "#313131"
                                : "#939393",
                            width: "max-content",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      </div>
                    );
                  })
                : null}
            </ScrollBarOverflowXHidden>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {isSmall ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: 80,
                  marginBottom: 24,
                }}
              >
                <SelectM
                  style={{}}
                  setValue={(value) => {
                    navigate(
                      `${route.category_product}/1?categoryValue=${clickedCategoryValue}&subCategoryValue=${clickedSubCategoryValue}&filterValue=${value}`
                    );
                  }}
                  value={filterValue}
                  dataList={filterList}
                  placeholder={"Lựa chọn"}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 80,
                  marginBottom: 20,
                  width: "100%",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "33.6px",
                    fontWeight: 600,
                    color: "#313131",
                  }}
                >
                  {subCategoryList.length > 0 && clickedSubCategoryValue
                    ? subCategoryList.find(
                        (item) => item.id === clickedSubCategoryValue
                      )?.title
                    : ""}
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {filterList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CategoryText
                          onClick={() => {
                            navigate(
                              `${route.category_product}/1?categoryValue=${clickedCategoryValue}&subCategoryValue=${clickedSubCategoryValue}&filterValue=${item.value}`
                            );
                          }}
                          $isActive={filterValue === item.value}
                        >
                          {item.label}
                        </CategoryText>
                        {filterList.length === index + 1 ? null : (
                          <div
                            style={{
                              height: 12,
                              width: 1,
                              background: "#C4C4C4",
                              margin: "0px 8px",
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: isMobile ? "24px 15px" : 20,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: isSmall ? "space-between" : "flex-start",
              }}
            >
              {categoryProductList.length > 0 ? (
                categoryProductList.map((item, index) => {
                  return <ProductItem key={index} data={item} />;
                })
              ) : (
                <Empty title={"Vẫn chưa có phần thưởng."} />
              )}
            </div>
          </div>

          {categoryProductList && pagination.totalCount > 20 ? (
            <div
              style={{
                marginTop: 50,
              }}
            >
              <Pagination
                route={route.category_product}
                queryString={`categoryValue=${clickedCategoryValue}&subCategoryValue=${clickedSubCategoryValue}&filterValue=${filterValue}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />
      </CategoryProductSection>
    </>
  );
};

export default CategoryProduct;

const CategoryProductSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const HomeIconMenuContain = styled.div`
  display: flex;
  margin-right: ${(props) => (props.$isMobile ? "16px" : "40px")};
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-width: ${(props) => (props.$isMobile ? "90px" : "116px")};
  max-width: ${(props) => (props.$isMobile ? "90px" : "116px")};
`;

const HomeIconMenuText = styled(PretendardText)`
  margin-top: 14px;
  font-size: ${(props) => (props.$isMobile ? "14px" : "18px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "26.1px")};
  font-weight: 500;
  color: rgb(49, 49, 49);
  text-align: center;
`;

const CategoryText = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  font-weight: 500;
  color: ${(props) => (props.$isActive ? "#ff649c" : "rgb(98, 98, 98)")};
  cursor: pointer;

  &:hover {
    color: #ff649c;
  }
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  margin-bottom: ${(props) => (props.$isMobile ? "40px" : "41px")};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: ${(props) => (props.$isSmall ? "flex-start" : "flex-start")};
  border-bottom: 1px solid rgb(220, 220, 220);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const ScrollBarOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: ${(props) => (props.$isSmall ? "flex-start" : "flex-start")};
  border-bottom: 1px solid rgb(220, 220, 220);

  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eeeeee;
    border-radius: 99px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
