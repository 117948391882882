import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../atoms/text/pretendardText";
import { route } from "../../router/route";

import PCContainer from "../atoms/layout/pcContainer";
import { errorHandler } from "../../util/errorHandler";
import BaseInfoApi from "../../api/baseInfoApi";

const Footer = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [baseInfoData, setBaseInfoData] = useState({
    createdAt: "",
    updatedAt: "",
    id: 0,
    companyName: "",
    ceo: "",
    businessNumber: "",
    reportNumber: "",
    address: "",
    tel: "",
    email: "",
    csInfo1: "",
    csInfo2: "",
    csInfo3: "",
  });

  useEffect(() => {
    baseInfoFunc();
  }, []);

  const baseInfoFunc = async () => {
    try {
      const response = await BaseInfoApi.getInfo();

      setBaseInfoData(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <FooterSection>
      <FooterWrapper>
        <PCContainer
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 27.52,
              }}
            >
              <img
                onClick={() => {
                  navigation(`${route.home}`);
                }}
                style={{
                  width: 135,
                  height: 36.96,
                  marginRight: 40,
                  cursor: "pointer",
                }}
                src="/assets/imgs/logo02.png"
                alt="logo02"
              />
              <MenuText
                onClick={() => {
                  navigation(`${route.provision}?category=Điều khoản sử dụng`);
                }}
              >
                Điều khoản sử dụng
              </MenuText>
              <MenuText
                onClick={() => {
                  navigation(`${route.provision}?category=Chính sách bảo mật`);
                }}
              >
                Chính sách bảo mật
              </MenuText>
              <MenuText
                onClick={() => {
                  navigation(`${route.customer_center}/1?category=Thông báo`);
                }}
              >
                Thông báo
              </MenuText>
              <MenuText
                onClick={() => {
                  navigation(`${route.customer_center}/1?category=Chính sách`);
                }}
                style={{
                  marginRight: 0,
                }}
              >
                Chính sách
              </MenuText>
            </div>

            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#626262",
                fontWeight: 400,
                marginBottom: 20,
              }}
            >
              {baseInfoData.companyName} ㅣ Người đại diện: {baseInfoData.ceo}
              <br />
              MST: {baseInfoData.businessNumber} Nơi cấp:{" "}
              {baseInfoData.reportNumber}
              <br />
              Địa chỉ: {baseInfoData.address}
              <br />
              Số điện thoại: {baseInfoData.tel} ㅣ Email: {baseInfoData.email}
              <br />
            </PretendardText>

            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#C4C4C4",
                fontWeight: 600,
                marginBottom: 5,
              }}
            >
              Bản quyền thuộc về Koviet
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#C4C4C4",
                fontWeight: 600,
              }}
            >
              Copyright ⓒ 2024 Koviet Mọi quyền được bảo lưu.
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              minWidth: 300,
            }}
          >
            <div
              style={{
                width: 84,
                height: 84,
                background: "#D9D9D9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#313131",
                  fontWeight: 600,
                }}
              >
                QR
              </PretendardText>
            </div>
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "19.6px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Enjoy Koviet on Your Phone!
            </PretendardText>

            <div
              style={{
                marginTop: 24,
                marginBottom: 4,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 8,
                }}
                src="/assets/icons/counseling.png"
                alt="counseling"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#313131",
                    fontWeight: 600,
                    marginBottom: 4,
                  }}
                >
                  TRUNG TÂM CS
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#313131",
                    fontWeight: 600,
                  }}
                >
                  {baseInfoData.csInfo1}
                </PretendardText>
              </div>
            </div>

            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "19.6px",
                color: "#939393",
                fontWeight: 400,
              }}
            >
              {baseInfoData.csInfo2}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "19.6px",
                color: "#939393",
                fontWeight: 400,
              }}
            >
              {baseInfoData.csInfo3}
            </PretendardText>
          </div>
        </PCContainer>
      </FooterWrapper>
    </FooterSection>
  );
};

export default Footer;

const FooterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
`;

const FooterWrapper = styled.div`
  width: 100%;
  height: 317px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
`;

const MenuText = styled(PretendardText)`
  cursor: pointer;
  font-size: 16px;
  line-height: 23.2px;
  color: #313131;
  font-weight: 600;
  margin-right: 40px;

  &:hover {
    color: #ff649c;
  }
`;
