import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import RegisterTypeItem from "../../components/organisms/register/registerTypeItem";

import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";

const RegisterType = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const location = useLocation();

  const { joinType, socialId, userData } = location.state || {}; // state가 없을 수도 있으므로 기본값 설정

  const [type, setType] = useState("");

  return (
    <>
      <RegisterTypeSection $isMobile={isMobile}>
        <PCContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: isMobile ? "0px 20px" : 0,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 24 : 32,
                lineHeight: isMobile ? "33.6px" : "44.8px",
                color: "#313131",
                textAlign: "center",
                fontWeight: 600,
                marginBottom: isMobile ? 40 : 52,
              }}
            >
              {isMobile ? "Đăng ký thành viên" : "Đăng ký thành viên"}
            </PretendardText>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RegisterTypeItem
                isOn={type === "CONSUMER"}
                onClick={() => {
                  setType("CONSUMER");
                  setTimeout(() => {
                    navigation(`${route.register}?type=CONSUMER`, {
                      state: {
                        socialId: socialId,
                        joinType: joinType,
                        userData: userData,
                      },
                    });
                  }, 600);
                }}
                style={{
                  marginRight: 0,
                  // marginRight: isMobile ? 0 : 30,
                  marginBottom: isMobile ? 25 : 0,
                }}
                title={"Đăng ký thành viên"}
                content={
                  "Bạn có thể tận hưởng việc mua sắm với tư cách là thành viên chung."
                }
                iconD={"/assets/icons/ic_register_86_d.png"}
                iconA={"/assets/icons/ic_register_86_a.png"}
              />

              {/* <RegisterTypeItem
                isOn={type === "INFLUENCER"}
                onClick={() => {
                  setType("INFLUENCER");
                  setTimeout(() => {
                    navigation(`${route.register}?type=INFLUENCER`, {
                      state: {
                        socialId: socialId,
                        joinType: joinType,
                        userData: userData,
                      },
                    });
                  }, 600);
                }}
                title={"Đăng ký dành cho Influencer"}
                content={
                  "Sau khi được phê duyệt bạn sẽ có thể quảng bá và mua sắm sản phẩm với những ưu đãi đặc biệt từ KOVIET."
                }
                iconD={"/assets/icons/ic_register_i_86_d.png"}
                iconA={"/assets/icons/ic_register_i_86_a.png"}
              /> */}
            </div>
          </div>
        </PCContainer>
      </RegisterTypeSection>
    </>
  );
};

export default RegisterType;

const RegisterTypeSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${(props) => (props.$isMobile ? "80px 0px" : "91px 0px")};
`;
