import React, { useState, useEffect, useMemo } from "react";
import useDisplay from "../../hooks/useDisplay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { route } from "../../router/route";

const FooterBtnGroup = (props) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();

  const [isAlways, setIsAlways] = useState(props.isAlways ? true : false);
  const [position, setPosition] = useState(0);
  const [isUp, setIsUp] = useState(false);
  const [isPlusBtn, setIsPlusBtn] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  let preScrollTop = 0;

  function onScroll() {
    setPosition(window.scrollY);
    let nextScrollTop = window.scrollY;
    if (preScrollTop < nextScrollTop) {
      setIsUp(false);
    } else {
      setIsUp(true);
    }
    preScrollTop = nextScrollTop;
  }

  useEffect(() => {
    if (position < 150) {
      setIsPlusBtn(false);
    }
  }, [position]);

  useEffect(() => {
    let isLogin = sessionStorage.getItem("isLogin");
    setIsLogin(isLogin);

    window.scrollTo(0, 0);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      {position > 150 || isAlways ? (
        <div className="fadeIn">
          <img
            src="/assets/icons/floating/floating_top.png"
            style={{
              position: "fixed",
              bottom: isPlusBtn
                ? isMobile
                  ? props.isBottomBtn
                    ? 324
                    : 274
                  : 318
                : isMobile
                ? props.isBottomBtn
                  ? 140
                  : 90
                : 100,
              right: isSmall ? 20 : 200,
              width: isMobile ? 52 : 60,
              height: isMobile ? 52 : 60,
              cursor: "pointer",
              zIndex: 98,
            }}
            alt="Back To Top Icon"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          {isPlusBtn ? (
            <>
              <img
                src="/assets/icons/floating/floating_call.png"
                style={{
                  position: "fixed",
                  bottom: isMobile ? (props.isBottomBtn ? 264 : 212) : 246,
                  right: isSmall ? 20 : 200,
                  width: isMobile ? 52 : 60,
                  height: isMobile ? 52 : 60,
                  cursor: "pointer",
                  zIndex: 98,
                }}
                alt="floating_call"
                onClick={() => {
                  window.location.href = `tel:${"+84 978.900.860"}`; //사용자 기기의 전화어
                }}
              />
              <img
                src="/assets/icons/floating/floating_docu.png"
                style={{
                  position: "fixed",
                  bottom: isMobile ? (props.isBottomBtn ? 203 : 152) : 174,
                  right: isSmall ? 20 : 200,
                  width: isMobile ? 52 : 60,
                  height: isMobile ? 52 : 60,
                  cursor: "pointer",
                  zIndex: 98,
                }}
                alt="floating_docu"
                onClick={() => {
                  if (isLogin) {
                    navigation(`${route.mypage_order_list}/1`);
                  } else {
                    toast("Có thể sử dụng sau khi đăng nhập.");
                  }
                }}
              />
              <img
                src="/assets/icons/floating/floating_zalo.png"
                style={{
                  position: "fixed",
                  bottom: isMobile ? (props.isBottomBtn ? 142 : 90) : 102,
                  right: isSmall ? 20 : 200,
                  width: isMobile ? 52 : 60,
                  height: isMobile ? 52 : 60,
                  cursor: "pointer",
                  zIndex: 98,
                }}
                alt="floating_zalo"
                onClick={() => {
                  window.open(
                    "https://zalo.me/0978900860",
                    "_blank",
                    "noopener, noreferrer"
                  );
                }}
              />
            </>
          ) : null}
          <img
            src={
              isPlusBtn
                ? "/assets/icons/floating/floating_close.png"
                : "/assets/icons/floating/floating_plus.png"
            }
            style={{
              position: "fixed",
              bottom: props.isBottomBtn && isMobile ? 80 : 30,
              right: isSmall ? 20 : 200,
              width: isMobile ? 52 : 60,
              height: isMobile ? 52 : 60,
              cursor: "pointer",
              zIndex: 98,
            }}
            alt="Contact Icon"
            onClick={() => {
              setIsPlusBtn(!isPlusBtn);
            }}
          />
        </div>
      ) : isUp && position < 150 ? (
        <div className="fadeOut">
          <img
            src="/assets/icons/floating/floating_top.png"
            style={{
              position: "fixed",
              bottom: isMobile ? (props.isBottomBtn ? 140 : 90) : 100,
              right: isSmall ? 20 : 200,
              width: isMobile ? 52 : 60,
              height: isMobile ? 52 : 60,
              cursor: "pointer",
              zIndex: 98,
            }}
            alt="Back To Top Icon"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          <img
            src="/assets/icons/floating/floating_plus.png"
            style={{
              position: "fixed",
              bottom: props.isBottomBtn && isMobile ? 80 : 30,
              right: isSmall ? 20 : 200,
              width: isMobile ? 52 : 60,
              height: isMobile ? 52 : 60,
              cursor: "pointer",
              zIndex: 98,
            }}
            alt="Contact Icon"
            onClick={() => {}}
          />
        </div>
      ) : null}
    </>
  );
};

export default FooterBtnGroup;
