import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

function InputSearchAddress(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        ...props.parentStyle,
      }}
    >
      <InputWrapper
        id={props.id ? props.id : "inputSearch"}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
        onKeyDown={props.onKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />

      <img
        onClick={() => {
          props.setDataToKeyword();
        }}
        src="/assets/icons/search.png"
        alt=""
        style={{
          position: "absolute",
          top: "18px",
          right: "20px",
          width: 18,
          height: 18,
          cursor: "pointer",
          ...props.searchStyle,
        }}
      />
    </div>
  );
}

export default InputSearchAddress;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  box-sizing: border-box;

  height: 55px;
  padding: 16px 15px;

  background: #ffffff;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  color: #313131;
  border: 2px solid #e8e8e8;
  border-radius: 8px;

  &::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    color: #c4c4c4;
  }

  &:focus {
    outline: none !important;
    border-color: #ff649c !important;
  }

  &:disabled {
    outline: none;
    background: #f5f5f5;
    color: #ababab;
  }
`;
