import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import moment from "moment";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";
import ProfileForm from "../../components/atoms/fileForm/profileForm";
import MainBtn from "../../components/atoms/button/mainBtn";
import TitleInput from "../../components/molecules/input/titleInput";
import Select from "../../components/atoms/select/select";
import Input from "../../components/atoms/input/input";
import FileForm from "../../components/atoms/fileForm/fileForm";

import useDisplay from "../../hooks/useDisplay";
import MypageMenuM from "../../components/templates/mypageMenuM";

import MemberApi from "../../api/memberApi";
import { errorHandler } from "../../util/errorHandler";
import CommonApi from "../../api/commonApi";
import { headerTriggerState } from "../../store/headerState";
import AuthApi from "../../api/authApi";

import AddressSearch from "../../components/organisms/addressSearch";

const AccountManage = () => {
  const navigation = useNavigate();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [headerTriggerStateAtom, setHeaderTriggerStateAtom] =
    useRecoilState(headerTriggerState);

  const [selectedFiles, setSelectedFiles] = useState();
  const [type, setType] = useState("CONSUMER");
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");

  const [oldPw, setOldPw] = useState("1231244124");

  const [isNewPw, setIsNewPw] = useState(false);
  const [pw, setPw] = useState("");
  const [pwType, setPwType] = useState("password");
  const [pwErrorM, setPwErrorM] = useState("");

  const [pwConfirm, setPwConfirm] = useState("");
  const [pwConfirmType, setPwConfirmType] = useState("password");
  const [pwConfirmErrorM, setPwConfirmErrorM] = useState("");

  const [recommender, setRecommender] = useState("");
  const [recommenderErrorM, setRecommenderErrorM] = useState("");
  const [recommenderCheckM, setRecommenderCheckM] = useState("");

  const [name, setName] = useState("");
  const [nameErrorM, setNameErrorM] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneErrorM, setPhoneErrorM] = useState("");

  const [birth, setBirth] = useState("");
  const [birthErrorM, setBirthErrorM] = useState("");

  const [address, setAddress] = useState();
  const [addressDR, setAddressDR] = useState();
  const [addressWard, setAddressWard] = useState();

  const [addressDetail, setAddressDetail] = useState("");

  const [gender, setGenderValue] = useState("");
  const [genderList, setGenderList] = useState([
    {
      value: "nam giới",
      label: "nam giới",
      type: "nam giới",
    },
    {
      value: "cô gái",
      label: "cô gái",
      type: "cô gái",
    },
  ]);

  const [snsLink, setSnsLink] = useState("");
  const [snsLinkErrorM, setSnsLinkErrorM] = useState("");

  const [bankName, setBankName] = useState("");
  const [bankNameErrorM, setBankNameErrorM] = useState("");

  const [bankNumber, setBankNumber] = useState("");
  const [bankNumberErrorM, setBankNumberErrorM] = useState("");

  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberErrorM, setAccountNumberErrorM] = useState("");

  const [joinType, setJoinType] = useState("");
  const [snsLinkList, setSnsLinkList] = useState([]);
  const [screenShotFiles, setScreenShotFiles] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);

  useEffect(() => {
    if (birth.length > 0) {
      const regex = /^\d{4}\/\d{2}\/\d{2}$/;

      if (!regex.test(birth)) {
        return setBirthErrorM("Hình thức ngày tháng không chính xác."); // 형식이 맞지 않으면 false 반환
      }

      // Date 객체로 변환
      const date = new Date(birth);

      // 날짜가 유효한지 확인 (유효하지 않으면 Invalid Date가 됨)
      if (isNaN(date.getTime())) {
        return setBirthErrorM("Hình thức ngày tháng không chính xác.");
      }

      setBirthErrorM("");
    }
  }, [birth]);

  useEffect(() => {
    myInfoFunc();
  }, []);

  const myInfoFunc = async () => {
    try {
      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      sessionStorage.setItem("name", myInfoRP.name);
      sessionStorage.setItem("profile", myInfoRP.imageServerFileName);

      setType(myInfoRP.role === "INFLUENCER" ? "INFLUENCER" : "CONSUMER");
      setEmail(myInfoRP.email);
      setName(myInfoRP.name);
      setNickname(myInfoRP.nickname);
      setRecommender(myInfoRP.recommenderID);
      setJoinType(myInfoRP.joinType);

      let snsLinkTemp = myInfoRP.snsLink ? JSON.parse(myInfoRP.snsLink) : "";

      if (snsLinkTemp && snsLinkTemp.length > 0) {
        setSnsLink(snsLinkTemp[0]);

        let snsLinkListTemp = snsLinkTemp.splice(1);

        if (snsLinkListTemp && snsLinkListTemp.length > 0) {
          let snsLinkListTemp02 = snsLinkListTemp.map((item, index) => {
            return {
              id: index,
              title: item,
            };
          });

          setSnsLinkList(snsLinkListTemp02);
        }
      }

      setScreenShotFiles(myInfoRP.screenshots);
      setName(myInfoRP.name);
      setPhone(myInfoRP.phoneNumber);
      if (myInfoRP.birth) {
        setBirth(moment(myInfoRP.birth).format("YYYY/MM/DD"));
      }

      if (myInfoRP.gender !== "NONE") {
        setGenderValue(myInfoRP.gender);
      }

      let addressArray = myInfoRP.address ? JSON.parse(myInfoRP.address) : [];

      if (addressArray[0]) {
        setAddress(addressArray[0]);
      }

      if (addressArray[1]) {
        setAddressDR(addressArray[1]);
      }

      if (addressArray[2]) {
        setAddressWard(addressArray[2]);
      }

      setAddressDetail(myInfoRP.detailAddress);

      setBankName(myInfoRP.bankName);
      setBankNumber(myInfoRP.accountHolderName);
      setAccountNumber(myInfoRP.accountNumber);

      if (myInfoRP.imageServerFileName) {
        const fileObject = {
          imageOriginFileName: myInfoRP.imageOriginFileName,
          imageServerFileName: myInfoRP.imageServerFileName,
        };

        setSelectedFiles(fileObject);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }
      if (error?.response?.status === 404) {
        return toast(`Thành viên không tồn tại.`);
      }

      errorHandler(error);
    }
  };

  const handleChange = async (e) => {
    const filesArray = Array.from(e.target.files);

    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop(),
        "profile/"
      );

      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        const fileObject = {
          imageOriginFileName: file.name,
          imageServerFileName: serverFileName,
          fileUrl: data.target.result,
        };

        setSelectedFiles(fileObject);
      };
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileType, path) => {
    try {
      const obj = {
        path: path,
        extension: fileType,
      };

      const response = await CommonApi.fileUpload(obj);

      await CommonApi.cloudFileUpload(response.data.data.presignedUrl, file);

      return response.data.data.serverFileName;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const handleScreenShotChange = async (e) => {
    if (screenShotFiles.length >= 3) {
      toast("Tối đa 3 trang");

      return;
    }

    const filesArray = Array.from(e.target.files);

    if (filesArray.length > 3) {
      toast("Tối đa 3 trang");
      return;
    }
    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop(),
        "influencer/screenshot/"
      );

      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        const fileObject = {
          screenshotOriginFileName: file.name,
          screenshotServerFileName: serverFileName,
          fileUrl: data.target.result,
        };

        setScreenShotFiles((prevFiles) => [...prevFiles, fileObject]);
      };
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const deleteFile = (fileName, id) => {
    let temp = screenShotFiles.filter(
      (item) => item.screenshotOriginFileName != fileName
    );

    setScreenShotFiles(temp);

    if (id) {
      setDeleteIds((prevID) => [...prevID, id]);
    }
  };

  const updateFunc = async () => {
    try {
      if (pw.length > 0) {
        if (!(pw && pwErrorM === "" && pwConfirm && pwConfirmErrorM === "")) {
          return toast("Vui lòng kiểm tra mật khẩu bạn muốn thay đổi.");
        }
      }

      let obj;
      let addressArray = [];

      if (addressDetail !== "") {
        if (!address) {
          return toast("Vui lòng nhập địa chỉ.");
        }
      }

      if (address) {
        addressArray.push(address);

        if (!addressDR) {
          return toast("Vui lòng nhập địa phương.");
        }
      }

      if (addressDR) {
        addressArray.push(addressDR);
      }

      if (addressWard) {
        addressArray.push(addressWard);
      }

      if (type === "INFLUENCER") {
        let selectedFilesTemp = screenShotFiles.filter(
          (item) => !item.hasOwnProperty("id")
        );

        let screenshots = selectedFilesTemp.map((item) => {
          return {
            screenshotOriginFileName: item.screenshotOriginFileName,
            screenshotServerFileName: item.screenshotServerFileName,
          };
        });

        let snsLinkTemp = "";
        if (snsLinkList.length > 0) {
          let snsLinkTemp02 = snsLinkList.map((item) => {
            return item.title;
          });

          snsLinkTemp = [snsLink, ...snsLinkTemp02];
        } else {
          snsLinkTemp = [snsLink];
        }

        obj = {
          name: name,
          phoneNumber: phone,
          birth: birth,
          gender: gender,
          address: JSON.stringify(addressArray),
          detailAddress: addressDetail,
          imageOriginFileName: selectedFiles
            ? selectedFiles.imageOriginFileName
            : "",
          imageServerFileName: selectedFiles
            ? selectedFiles.imageServerFileName
            : "",
          snsLink: JSON.stringify(snsLinkTemp),
          screenshots: screenshots,
          deleteIds: deleteIds,
          passwordChangeFlag: pw ? true : false,
          password: pw,
          bankName: bankName,
          accountHolderName: bankNumber,
          accountNumber: accountNumber,
        };
      } else {
        obj = {
          name: name,
          phoneNumber: phone,
          birth: birth,
          gender: gender,
          address: JSON.stringify(addressArray),
          detailAddress: addressDetail,
          imageOriginFileName: selectedFiles
            ? selectedFiles.imageOriginFileName
            : "",
          imageServerFileName: selectedFiles
            ? selectedFiles.imageServerFileName
            : "",
          recommenderID: recommender,
          passwordChangeFlag: pw ? true : false,
          password: pw,
        };
      }

      const response = await MemberApi.updateMyInfo(obj);

      await myInfoFunc();
      toast("Đã chỉnh sửa.");

      window.scrollTo(0, 0);

      setHeaderTriggerStateAtom(!headerTriggerStateAtom);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return;
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (pw.length > 0) {
      var regType1 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

      if (!regType1.test(pw)) {
        return setPwErrorM(
          "Vui lòng nhập tổ hợp ký tự đặc biệt, số, tiếng Anh 8-16 ký tự."
        );
      }

      if (pw.length < 8) {
        return setPwErrorM("Vui lòng nhập mật khẩu trên 8 chữ số.");
      }

      setPwErrorM("");
    }
  }, [pw]);

  useEffect(() => {
    if (pwConfirm.length > 0) {
      if (pwConfirm !== pw) {
        return setPwConfirmErrorM("Mật khẩu không khớp.");
      }

      setPwConfirmErrorM("");
    }
  }, [pwConfirm]);

  useEffect(() => {
    if (recommender.length > 0) {
      recommenderCheckFunc();
    }
  }, [recommender]);

  const recommenderCheckFunc = async () => {
    try {
      const obj = {
        recommenderID: recommender,
      };

      const response = await AuthApi.recommendCheck(obj);

      setRecommenderErrorM("");
      setRecommenderCheckM("Đã hoàn thành việc đăng ký ID người giới thiệu.");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (
            error.response.data.message ===
            "입력한 추천인아이디가 존재하지 않습니다."
          ) {
            setRecommenderErrorM(`Đây là người giới thiệu chưa được đăng ký.`);
          }
        }
      }
    }
  };

  return (
    <>
      <AccountManageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "28px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? 0 : isTablet ? "60px 18%" : "60px 220px",
                background: "#fff",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#222222",
                  fontWeight: 600,
                  textAlign: "center",
                  marginBottom: 40,
                }}
              >
                Thay đổi thông tin
              </PretendardText>

              <ProfileForm
                handleChange={handleChange}
                profile={
                  selectedFiles
                    ? `${process.env.REACT_APP_MEDIA_URL}${selectedFiles.imageServerFileName}`
                    : ""
                }
              />

              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "26.1px",
                  color: "#4B4D4C",
                  fontWeight: 600,
                  textAlign: "center",
                  marginBottom: 20,
                }}
              >
                {name}
              </PretendardText>

              <div
                style={{
                  width: "100%",
                }}
              >
                <TitleInput
                  title={"Email"}
                  placeholder={""}
                  type={"text"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  style={{}}
                  disabled={true}
                />
                {joinType === "NORMAL" ? (
                  <>
                    <TitleInput
                      title={"Mật khẩu"}
                      essential={false}
                      placeholder={""}
                      type={"password"}
                      onChange={(e) => {
                        setOldPw(e.target.value);
                      }}
                      value={oldPw}
                      style={{
                        marginTop: 20,
                      }}
                      isRightButton={true}
                      isRightButtonText={"Thay đổi"}
                      rightButtonOnClick={() => {
                        setIsNewPw(true);
                      }}
                      isRightButtonStyle={{
                        right: "-4%",
                      }}
                    />

                    <TitleInput
                      disabled={!isNewPw}
                      inputType={"password"}
                      setInputType={setPwType}
                      type={pwType}
                      title={"Mật khẩu mới"}
                      essential={false}
                      placeholder={"Nhấp vào nút thay đổi để đặt mật khẩu mới."}
                      style={{
                        marginTop: 20,
                      }}
                      onChange={(e) => {
                        setPw(e.target.value);
                      }}
                      value={pw}
                      warningMessage={pwErrorM}
                    />

                    <TitleInput
                      disabled={!isNewPw}
                      inputType={"password"}
                      setInputType={setPwConfirmType}
                      type={pwConfirmType}
                      title={"Xác nhận mật khẩu mới"}
                      essential={false}
                      placeholder={"Nhấp vào nút thay đổi để đặt mật khẩu mới."}
                      style={{
                        marginTop: 20,
                      }}
                      onChange={(e) => {
                        setPwConfirm(e.target.value);
                      }}
                      value={pwConfirm}
                      warningMessage={pwConfirmErrorM}
                    />
                  </>
                ) : null}

                {/* {type === "CONSUMER" ? (
                  <TitleInput
                    title={"ID người giới thiệu"}
                    essential={false}
                    placeholder={
                      "Vui lòng nhập biệt hiệu của người có ảnh hưởng."
                    }
                    type={"text"}
                    onChange={(e) => {
                      setRecommender(e.target.value);
                    }}
                    value={recommender}
                    warningMessage={recommenderErrorM}
                    checkMessage={recommenderCheckM}
                    style={{
                      marginTop: 20,
                    }}
                  />
                ) : null} */}

                {type === "INFLUENCER" ? (
                  <TitleInput
                    title={"Đặt tên"}
                    placeholder={""}
                    type={"text"}
                    onChange={(e) => {
                      setNickname(e.target.value);
                    }}
                    value={nickname}
                    style={{
                      marginTop: 20,
                    }}
                    disabled={true}
                  />
                ) : null}

                {type === "INFLUENCER" ? (
                  <div>
                    <TitleInput
                      title={"Liên kết SNS"}
                      essential={false}
                      placeholder={"Vui lòng nhập liên kết SNS của bạn."}
                      type={"text"}
                      onChange={(e) => {
                        setSnsLink(e.target.value);
                      }}
                      value={snsLink}
                      warningMessage={snsLinkErrorM}
                      style={{
                        marginTop: 20,
                      }}
                    />

                    {snsLinkList.length > 0
                      ? snsLinkList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                position: "relative",
                                marginTop: 10,
                                flex: 1,
                              }}
                            >
                              <Input
                                defaultValue={item.title}
                                onChange={(e) => {
                                  item.title = e.target.value;
                                }}
                                type={"text"}
                                placeholder={
                                  "Vui lòng nhập liên kết SNS của bạn."
                                }
                              />
                              {snsLinkList.length === index + 1 ? (
                                <img
                                  onClick={() => {
                                    let temp = snsLinkList.filter(
                                      (item02) => item02.id !== index
                                    );

                                    setSnsLinkList(temp);
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 13,
                                    width: 18,
                                    height: 18,
                                    cursor: "pointer",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                  src="/assets/icons/ic-closed-30.png"
                                  alt="ic-closed-30"
                                />
                              ) : null}
                            </div>
                          );
                        })
                      : null}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <div
                        onClick={() => {
                          let temp = [
                            ...snsLinkList,
                            {
                              id: snsLinkList.length,
                              snsLink: "",
                            },
                          ];

                          setSnsLinkList(temp);
                        }}
                        style={{
                          cursor: "pointer",
                          padding: "6px 12px",
                          borderRadius: 6,
                          border: "2px solid #E8E8E8",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 14,
                            lineHeight: "19.6px",
                            color: "#313131",
                            fontWeight: 600,
                            marginRight: 2,
                          }}
                        >
                          Thêm liên kết
                        </PretendardText>
                        <img
                          style={{
                            width: 14,
                            height: 14,
                          }}
                          src="/assets/icons/ic_plus_14.png"
                          alt="ic_plus_14"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {type === "INFLUENCER" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 4,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "23.2px",
                          color: "#313131",
                          fontWeight: 700,
                        }}
                      >
                        Ảnh chụp màn hình
                      </PretendardText>
                    </div>
                    <PretendardText
                      style={{
                        fontSize: 12,
                        lineHeight: "16.8px",
                        color: "#939393",
                        fontWeight: 500,
                        marginBottom: 8,
                      }}
                    >
                      *Vui lòng chụp và tải lên màn hình SNS của bạn để xác minh
                      danh tính của bạn.. <br />
                      (Tối đa 3 trang)
                    </PretendardText>

                    {screenShotFiles.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          flexWrap: "wrap",
                          gap: 16,
                        }}
                      >
                        {screenShotFiles.map((item, index) => {
                          return (
                            <div key={index} style={{ position: "relative" }}>
                              <img
                                style={{
                                  width: 80,
                                  height: 80,
                                  borderRadius: 8,
                                }}
                                src={`${process.env.REACT_APP_MEDIA_URL}${item.screenshotServerFileName}`}
                                alt="file"
                              />

                              <img
                                onClick={() => {
                                  deleteFile(
                                    item.screenshotOriginFileName,
                                    item.id
                                  );
                                }}
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: 3,
                                  right: 3,
                                }}
                                src="/assets/icons/subBTn_7_deleteBTN.png"
                                alt="subBTn_7_deleteBTN"
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    <FileForm
                      multiple={"multiple"}
                      handleChange={handleScreenShotChange}
                    />
                  </div>
                ) : null}

                <TitleInput
                  title={"Tên"}
                  essential={false}
                  placeholder={"Hãy nhập tên."}
                  type={"text"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  warningMessage={nameErrorM}
                  style={{
                    marginTop: 20,
                  }}
                />

                <TitleInput
                  title={"Số điện thoại"}
                  essential={false}
                  placeholder={"000-0000-0000"}
                  type={"text"}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  warningMessage={phoneErrorM}
                  style={{
                    marginTop: 20,
                  }}
                />

                <TitleInput
                  title={"Ngày sinh"}
                  essential={false}
                  placeholder={"YYYY/MM/DD"}
                  type={"text"}
                  onChange={(e) => {
                    setBirth(e.target.value);
                  }}
                  value={birth}
                  warningMessage={birthErrorM}
                  style={{
                    marginTop: 20,
                  }}
                  maxLength={10}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                    marginTop: 20,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#313131",
                      fontWeight: 700,
                    }}
                  >
                    Giới tính
                  </PretendardText>
                </div>
                <Select
                  style={{}}
                  setValue={setGenderValue}
                  value={gender}
                  dataList={genderList}
                  placeholder={"Giới tính"}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                    marginTop: 20,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#313131",
                      fontWeight: 700,
                    }}
                  >
                    Địa chỉ
                  </PretendardText>
                </div>
                <AddressSearch
                  address={address}
                  setAddress={setAddress}
                  addressDR={addressDR}
                  setAddressDR={setAddressDR}
                  addressWard={addressWard}
                  setAddressWard={setAddressWard}
                />

                <Input
                  placeholder={"Vui lòng nhập địa chỉ chi tiết."}
                  value={addressDetail}
                  onChange={(e) => {
                    setAddressDetail(e.target.value);
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "16.8px",
                    color: "#939393",
                    fontWeight: 600,
                    marginTop: 4,
                  }}
                >
                  Địa chỉ chi tiết
                </PretendardText>

                {type === "INFLUENCER" ? (
                  <>
                    <TitleInput
                      title={"Thông tin tài khoản - tên ngân hàng"}
                      essential={false}
                      placeholder={"Vui lòng nhập tên ngân hàng."}
                      type={"text"}
                      onChange={(e) => {
                        setBankName(e.target.value);
                      }}
                      value={bankName}
                      warningMessage={bankNameErrorM}
                      style={{
                        marginTop: 20,
                      }}
                    />
                    <TitleInput
                      title={"Thông tin tài khoản - số tài khoản"}
                      essential={false}
                      placeholder={"Vui lòng nhập số tài khoản."}
                      type={"text"}
                      onChange={(e) => {
                        setBankNumber(e.target.value);
                      }}
                      value={bankNumber}
                      warningMessage={bankNumberErrorM}
                      style={{
                        marginTop: 20,
                      }}
                    />
                    <TitleInput
                      title={"Thông tin tài khoản - Chủ tài khoản"}
                      essential={false}
                      placeholder={"Vui lòng nhập tên chủ tài khoản."}
                      type={"text"}
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                      }}
                      value={accountNumber}
                      warningMessage={accountNumberErrorM}
                      style={{
                        marginTop: 20,
                      }}
                    />
                  </>
                ) : null}
              </div>

              <MainBtn
                disabled={birthErrorM === "" ? false : true}
                onClick={() => {
                  updateFunc();
                }}
                style={{
                  marginTop: 40,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  Lưu thông tin
                </PretendardText>
              </MainBtn>
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </AccountManageSection>
    </>
  );
};

export default AccountManage;

const AccountManageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SearchContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 225px;

  z-index: 4;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;

  color: #313131;
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding-left: 18px;

  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;
