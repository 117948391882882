import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./App.css";
import Footer from "./components/templates/footer";
import FooterM from "./components/templates/footerM";
import Header from "./components/templates/header";
import HeaderM from "./components/templates/headerM";
import useDisplay from "./hooks/useDisplay";

import Router from "./router/router";
import ScrollToTop from "./util/scrollToTop";

import { headerMenuState, isLoginTrigger } from "./store/headerState";
import CategoryApi from "./api/categoryApi";
import { route } from "./router/route";
import { errorHandler } from "./util/errorHandler";
import MemberApi from "./api/memberApi";
import AuthApi from "./api/authApi";

function App() {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [cookies, setCookie, removeCookie] = useCookies(["autoLogin"]);
  const isSmall = isMobile || isTablet;

  const [headerMenuStateAtom, setHeaderMenuStateAtom] =
    useRecoilState(headerMenuState);

  const [isLoginTriggerAtom, setIsLoginTriggerAtom] =
    useRecoilState(isLoginTrigger);

  useEffect(() => {
    if (cookies.autoLogin) {
      autoLoginFunc();
    }
  }, [cookies]);

  const autoLoginFunc = async () => {
    try {
      const response = await AuthApi.getNewToken();

      const newToken = response.data.data.accessToken;
      // new 액세스 토큰 변수 저장
      sessionStorage.setItem("token", newToken);

      const isLoginAtom = sessionStorage.getItem("isLogin");

      if (isLoginAtom) {
      } else {
        const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

        sessionStorage.setItem("isLogin", "true");
        sessionStorage.setItem("email", myInfoRP.email);
        sessionStorage.setItem("id", myInfoRP.id);
        sessionStorage.setItem("role", myInfoRP.role);
        sessionStorage.setItem("name", myInfoRP.name);
        sessionStorage.setItem("profile", myInfoRP.imageServerFileName);
        sessionStorage.setItem("recommendID", myInfoRP.recommendID);

        setIsLoginTriggerAtom(true);

        navigation(route.home);
      }
    } catch (error) {
      // window.location.href = route.login + "?refresh=true";
      // if (error?.response?.status === 401) {
      //   return  ;
      // }
      // if (error?.response?.status === 404) {
      //   return toast(`Thành viên không tồn tại.`);
      // }
      // errorHandler(error);
    }
  };

  useEffect(() => {
    categoryListFunc();
  }, [isSmall]);

  const categoryListFunc = async () => {
    try {
      const response = await CategoryApi.getList();

      if (isSmall) {
        setHeaderMenuStateAtom([
          {
            title: "Sản phẩm mới",
            activation: true,
            childCategories: [],
            src: `${route.new_product}/1`,
          },
          {
            title: "Bán chạy",
            activation: true,
            childCategories: [],
            src: `${route.best_product}/1`,
          },
          {
            title: "Event của tháng này",
            activation: true,
            childCategories: [],
            src: `${route.event_product}/1`,
          },
          ...response.data.data.content,
          {
            title: "Khuyến mãi đặc biệt",
            activation: true,
            src: `${route.exhibition_list}/1`,
            childCategories: [
              {
                title: "Khuyến mãi đặc biệt đang diễn ra",
                activation: true,
                src: `${route.exhibition_list}/1`,
              },
              {
                title: "Khuyến mãi đặc biệt đã kết thúc",
                activation: true,
                src: `${route.exhibition_list}/1`,
              },
            ],
          },
          // {
          //   title: "TOP Influencers",
          //   activation: true,
          //   childCategories: [],
          //   src: `${route.influencer_list}/1`,
          // },
          {
            title: "Hỗ trợ khách hàng",
            activation: true,
            src: `${route.customer_center}/1?category=Thông báo`,
            childCategories: [
              {
                title: "Thông báo",
                activation: true,
                src: `${route.customer_center}/1?category=Thông báo`,
              },
              {
                title: "Chính sách",
                activation: true,
                src: `${route.customer_center}/1?category=Chính sách`,
              },
            ],
          },
        ]);
      } else {
        setHeaderMenuStateAtom([
          ...response.data.data.content,
          {
            title: "Khuyến mãi đặc biệt",
            activation: true,
            src: `${route.exhibition_list}/1`,
            childCategories: [
              {
                title: "Khuyến mãi đặc biệt đang diễn ra",
                activation: true,
                src: `${route.exhibition_list}/1`,
              },
              {
                title: "Khuyến mãi đặc biệt đã kết thúc",
                activation: true,
                src: `${route.exhibition_list}/1`,
              },
            ],
          },
          // {
          //   title: "TOP Influencers",
          //   activation: true,
          //   childCategories: [],
          //   src: `${route.influencer_list}/1`,
          // },
          {
            title: "Hỗ trợ khách hàng",
            activation: true,
            src: `${route.customer_center}/1?category=Thông báo`,
            childCategories: [
              {
                title: "Thông báo",
                activation: true,
                src: `${route.customer_center}/1?category=Thông báo`,
              },
              {
                title: "Chính sách",
                activation: true,
                src: `${route.customer_center}/1?category=Chính sách`,
              },
            ],
          },
        ]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ScrollToTop />
      {isSmall ? <HeaderM /> : <Header />}

      <Router />
      {isSmall ? <FooterM /> : <Footer />}

      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
      />
    </>
  );
}

export default App;
