import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import InputSearchAddress from "../atoms/input/inputSearchAddress";
import EfulfillApi from "../../api/efulfillApi";

function AddressSearch({
  address,
  setAddress,
  addressDR,
  setAddressDR,
  addressWard,
  setAddressWard,
}) {
  const [isSearch, setIsSearch] = useState(false);
  const [addressIValue, setAddressIValue] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressFilterList, setAddressFilterList] = useState([]);

  const [isSearch02, setIsSearch02] = useState(false);
  const [addressDRIValue, setAddressDRIValue] = useState("");
  const [addressDRList, setAddressDRList] = useState([]);
  const [addressDRFilterList, setAddressDRFilterList] = useState([]);

  const [isSearch03, setIsSearch03] = useState(false);
  const [addressWardIValue, setAddressWardIValue] = useState("");
  const [addressWardList, setAddressWardList] = useState([]);
  const [addressWardFilterList, setAddressWardFilterList] = useState([]);

  useEffect(() => {
    efulfillAddressFunc();
  }, []);

  const efulfillAddressFunc = async () => {
    try {
      await EfulfillApi.login();
      const response = (await EfulfillApi.getAddressList()).data.data;
      setAddressList(response.data);
      setAddressFilterList(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (addressIValue) {
      let temp = addressList.filter((item) =>
        item.provinceName.toLowerCase().includes(addressIValue.toLowerCase())
      );

      // 정렬: 키워드가 첫 글자에 매칭되는 항목 우선
      // temp.sort((a, b) => {
      //   const startsWithA = a.provinceName
      //     .toLowerCase()
      //     .startsWith(addressIValue.toLowerCase());
      //   const startsWithB = b.provinceName
      //     .toLowerCase()
      //     .startsWith(addressIValue.toLowerCase());

      //   if (startsWithA && !startsWithB) {
      //     return -1; // a를 앞에 둔다
      //   } else if (!startsWithA && startsWithB) {
      //     return 1; // b를 앞에 둔다
      //   }
      //   return 0; // 순서를 유지
      // });

      setAddressFilterList(temp);
    } else {
      setAddressFilterList(addressList);
    }
  }, [addressIValue]);

  useEffect(() => {
    if (address) {
      efulfillAddressDTFunc();
    } else {
      setAddressDR(null);
      setAddressWard(null);
    }
  }, [address]);

  const efulfillAddressDTFunc = async () => {
    try {
      const response = (
        await EfulfillApi.getAddressDistrictList(address.provinceCode)
      ).data.data;

      setAddressDRList(response.data);
      setAddressDRFilterList(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (addressDRIValue) {
      let temp = addressDRList.filter((item) =>
        item.provinceName.toLowerCase().includes(addressDRIValue.toLowerCase())
      );

      // 정렬: 키워드가 첫 글자에 매칭되는 항목 우선
      // temp.sort((a, b) => {
      //   const startsWithA = a.provinceName
      //     .toLowerCase()
      //     .startsWith(addressIValue.toLowerCase());
      //   const startsWithB = b.provinceName
      //     .toLowerCase()
      //     .startsWith(addressIValue.toLowerCase());

      //   if (startsWithA && !startsWithB) {
      //     return -1; // a를 앞에 둔다
      //   } else if (!startsWithA && startsWithB) {
      //     return 1; // b를 앞에 둔다
      //   }
      //   return 0; // 순서를 유지
      // });

      setAddressDRFilterList(temp);
    } else {
      setAddressDRFilterList(addressDRList);
    }
  }, [addressDRIValue]);

  useEffect(() => {
    if (addressDR) {
      efulfillAddressWardFunc();
    } else {
      setAddressWard(null);
    }
  }, [addressDR]);

  const efulfillAddressWardFunc = async () => {
    try {
      const response = (
        await EfulfillApi.getAddressWardsList(addressDR.districtCode)
      ).data.data;

      setAddressWardList(response.data);
      setAddressWardFilterList(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (addressWardIValue) {
      let temp = addressWardList.filter((item) =>
        item.provinceName
          .toLowerCase()
          .includes(addressWardIValue.toLowerCase())
      );

      // 정렬: 키워드가 첫 글자에 매칭되는 항목 우선
      // temp.sort((a, b) => {
      //   const startsWithA = a.provinceName
      //     .toLowerCase()
      //     .startsWith(addressIValue.toLowerCase());
      //   const startsWithB = b.provinceName
      //     .toLowerCase()
      //     .startsWith(addressIValue.toLowerCase());

      //   if (startsWithA && !startsWithB) {
      //     return -1; // a를 앞에 둔다
      //   } else if (!startsWithA && startsWithB) {
      //     return 1; // b를 앞에 둔다
      //   }
      //   return 0; // 순서를 유지
      // });

      setAddressWardFilterList(temp);
    } else {
      setAddressWardFilterList(addressWardList);
    }
  }, [addressWardIValue]);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          marginBottom: 10,
        }}
      >
        {address ? (
          <div
            style={{
              width: "100%",
              minHeight: 55,
              padding: "16px 15px",
              border: "1px solid #b8bfc4",
              borderRadius: 8,
              position: "relative",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#EEEEEE",
                borderRadius: 4,
                padding: "2px 10px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  color: "#808991",
                  fontWeight: 700,
                }}
              >
                {address.provinceName}
              </PretendardText>

              <img
                onClick={() => {
                  setAddress(null);
                }}
                style={{
                  width: 14,
                  height: 14,
                  cursor: "pointer",
                  marginLeft: 10,
                }}
                src="/assets/icons/ic-closed-30.png"
                alt="ic-closed-30"
              />
            </div>

            <img
              onClick={() => {
                setIsSearch(true);
              }}
              src="/assets/icons/search.png"
              alt=""
              style={{
                position: "absolute",
                top: "18px",
                right: "20px",
                width: 18,
                height: 18,
                cursor: "pointer",
              }}
            />
          </div>
        ) : (
          <InputSearchAddress
            style={{}}
            parentStyle={{
              width: "100%",
            }}
            placeholder={"Tỉnh/Thành phố"}
            value={addressIValue}
            onChange={(e) => {
              setAddressIValue(e);
              setIsSearch(true);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsSearch(true);
              }
            }}
            setDataToKeyword={() => {
              setIsSearch(true);
            }}
            onFocus={() => {
              setIsSearch(true);
            }}
            // onBlur={()=>{}}
          />
        )}
        <PretendardText
          style={{
            fontSize: 12,
            lineHeight: "16.8px",
            color: "#939393",
            fontWeight: 600,
            marginTop: 4,
          }}
        >
          Tỉnh/Thành phố
        </PretendardText>

        {isSearch ? (
          <SearchContain
            style={{
              zIndex: 6,
            }}
          >
            {addressFilterList.map((item, index) => {
              return (
                <SelectSpanText
                  key={index}
                  onClick={() => {
                    setAddress(item);
                    setIsSearch(false);
                    setAddressIValue("");

                    if (address?.provinceName !== item.provinceName) {
                      setAddressDR(null);
                      setAddressWard(null);
                    }
                  }}
                >
                  <SelectValueText>{item.provinceName}</SelectValueText>
                </SelectSpanText>
              );
            })}
          </SearchContain>
        ) : null}
      </div>

      {address ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            marginBottom: 10,
          }}
        >
          {addressDR ? (
            <div
              style={{
                width: "100%",
                minHeight: 55,
                padding: "16px 15px",
                border: "1px solid #b8bfc4",
                borderRadius: 8,
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                  background: "#EEEEEE",
                  borderRadius: 4,
                  padding: "2px 10px",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    color: "#808991",
                    fontWeight: 700,
                  }}
                >
                  {addressDR.districtName}
                </PretendardText>

                <img
                  onClick={() => {
                    setAddressDR(null);
                  }}
                  style={{
                    width: 14,
                    height: 14,
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                  src="/assets/icons/ic-closed-30.png"
                  alt="ic-closed-30"
                />
              </div>

              <img
                onClick={() => {
                  setIsSearch02(true);
                }}
                src="/assets/icons/search.png"
                alt=""
                style={{
                  position: "absolute",
                  top: "18px",
                  right: "20px",
                  width: 18,
                  height: 18,
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <InputSearchAddress
              style={{}}
              parentStyle={{
                width: "100%",
              }}
              placeholder={"Quận/Huyện"}
              value={addressDRIValue}
              onChange={(e) => {
                setAddressDRIValue(e);
                setIsSearch02(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsSearch02(true);
                }
              }}
              setDataToKeyword={() => {
                setIsSearch02(true);
              }}
              onFocus={() => {
                setIsSearch02(true);
              }}
            />
          )}
          <PretendardText
            style={{
              fontSize: 12,
              lineHeight: "16.8px",
              color: "#939393",
              fontWeight: 600,
              marginTop: 4,
            }}
          >
            Quận/Huyện
          </PretendardText>

          {isSearch02 ? (
            <SearchContain
              style={{
                zIndex: 5,
              }}
            >
              {addressDRFilterList.map((item, index) => {
                return (
                  <SelectSpanText
                    key={index}
                    onClick={() => {
                      setAddressDR(item);
                      setIsSearch02(false);
                      setAddressDRIValue("");

                      if (addressDR?.districtName !== item.districtName) {
                        setAddressWard(null);
                      }
                    }}
                  >
                    <SelectValueText>{item.districtName}</SelectValueText>
                  </SelectSpanText>
                );
              })}
            </SearchContain>
          ) : null}
        </div>
      ) : null}

      {addressDR ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            marginBottom: 10,
          }}
        >
          {addressWard ? (
            <div
              style={{
                width: "100%",
                minHeight: 55,
                padding: "16px 15px",
                border: "1px solid #b8bfc4",
                borderRadius: 8,
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                  background: "#EEEEEE",
                  borderRadius: 4,
                  padding: "2px 10px",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    color: "#808991",
                    fontWeight: 700,
                  }}
                >
                  {addressWard.wardName}
                </PretendardText>

                <img
                  onClick={() => {
                    setAddressWard(null);
                  }}
                  style={{
                    width: 14,
                    height: 14,
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                  src="/assets/icons/ic-closed-30.png"
                  alt="ic-closed-30"
                />
              </div>

              <img
                onClick={() => {
                  setIsSearch03(true);
                }}
                src="/assets/icons/search.png"
                alt=""
                style={{
                  position: "absolute",
                  top: "18px",
                  right: "20px",
                  width: 18,
                  height: 18,
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <InputSearchAddress
              style={{}}
              parentStyle={{
                width: "100%",
              }}
              placeholder={"Phường/Xã"}
              value={addressWardIValue}
              onChange={(e) => {
                setAddressWardIValue(e);
                setIsSearch03(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsSearch03(true);
                }
              }}
              setDataToKeyword={() => {
                setIsSearch03(true);
              }}
              onFocus={() => {
                setIsSearch03(true);
              }}
            />
          )}
          <PretendardText
            style={{
              fontSize: 12,
              lineHeight: "16.8px",
              color: "#939393",
              fontWeight: 600,
              marginTop: 4,
            }}
          >
            Phường/Xã
          </PretendardText>

          {isSearch03 ? (
            <SearchContain>
              {addressWardFilterList.map((item, index) => {
                return (
                  <SelectSpanText
                    key={index}
                    onClick={() => {
                      setAddressWard(item);
                      setIsSearch03(false);
                      setAddressWardIValue("");
                    }}
                  >
                    <SelectValueText>{item.wardName}</SelectValueText>
                  </SelectSpanText>
                );
              })}
            </SearchContain>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default AddressSearch;

const SearchContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 225px;

  z-index: 4;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;

  color: #313131;
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding-left: 18px;

  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;
