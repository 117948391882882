import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../atoms/text/pretendardText";
import { route } from "../../router/route";

import BaseInfoApi from "../../api/baseInfoApi";
import { errorHandler } from "../../util/errorHandler";

const FooterM = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [baseInfoData, setBaseInfoData] = useState({
    createdAt: "",
    updatedAt: "",
    id: 0,
    companyName: "",
    ceo: "",
    businessNumber: "",
    reportNumber: "",
    address: "",
    tel: "",
    email: "",
    csInfo1: "",
    csInfo2: "",
    csInfo3: "",
  });

  useEffect(() => {
    baseInfoFunc();
  }, []);

  const baseInfoFunc = async () => {
    try {
      const response = await BaseInfoApi.getInfo();
      setBaseInfoData(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <FooterMSection>
      <FooterMWrapper>
        <img
          onClick={() => {
            navigation(`${route.home}`);
          }}
          style={{
            width: 110,
            height: 30,
            marginBottom: 20,
            cursor: "pointer",
          }}
          src="/assets/imgs/logo02.png"
          alt="logo02"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "13px 40px",
          }}
        >
          <MenuText
            onClick={() => {
              navigation(route.provision);
            }}
          >
            Điều khoản sử dụng
          </MenuText>
          <MenuText
            onClick={() => {
              navigation(route.provision);
            }}
          >
            Chính sách bảo mật
          </MenuText>
          <MenuText
            onClick={() => {
              navigation(`${route.customer_center}/1?category=Thông báo`);
            }}
          >
            Thông báo
          </MenuText>
          <MenuText
            onClick={() => {
              navigation(`${route.customer_center}/1?category=Chính sách`);
            }}
          >
            Chính sách
          </MenuText>
        </div>

        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "19.6px",
            color: "#626262",
            fontWeight: 400,
            margin: "20px 0px 15px 0px",
          }}
        >
          {baseInfoData.companyName}ㅣ Người đại diện:{baseInfoData.ceo}
          <br />
          MST: {baseInfoData.businessNumber} Nơi cấp:{" "}
          {baseInfoData.reportNumber}
          <br />
          Địa chỉ: {baseInfoData.address}
          <br />
          Số điện thoại: {baseInfoData.tel} ㅣ Email: {baseInfoData.email}
        </PretendardText>

        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "19.6px",
            color: "#C4C4C4",
            fontWeight: 600,
            marginBottom: 5,
          }}
        >
          Bản quyền thuộc về Koviet
        </PretendardText>
        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "19.6px",
            color: "#C4C4C4",
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Copyright ⓒ 2024 Koviet Mọi quyền được bảo lưu.
        </PretendardText>

        <div
          style={{
            width: 64,
            height: 64,
            background: "#D9D9D9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              fontSize: 14,
              lineHeight: "19.6px",
              color: "#313131",
              fontWeight: 600,
            }}
          >
            QR
          </PretendardText>
        </div>

        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "19.6px",
            color: "#313131",
            fontWeight: 600,
          }}
        >
          Enjoy Koviet on Your Phone!
        </PretendardText>

        <div
          style={{
            marginTop: 30,
            marginBottom: 15,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 8,
            }}
            src="/assets/icons/counseling.png"
            alt="counseling"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 600,
                marginBottom: 4,
              }}
            >
              TRUNG TÂM CS
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              {baseInfoData.csInfo1}
            </PretendardText>
          </div>
        </div>

        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "19.6px",
            color: "#939393",
            fontWeight: 400,
          }}
        >
          {baseInfoData.csInfo2}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "19.6px",
            color: "#939393",
            fontWeight: 400,
          }}
        >
          {baseInfoData.csInfo3}
        </PretendardText>
      </FooterMWrapper>
    </FooterMSection>
  );
};

export default FooterM;

const FooterMSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
  position: relative;
`;

const FooterMWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  padding: 40px 20px;
`;

const MenuText = styled(PretendardText)`
  cursor: pointer;
  font-size: 14px;
  line-height: 19.6px;
  color: #313131;
  font-weight: 600;

  &:hover {
    color: #ff649c;
  }
`;
